import './entry.d/frontend';
import '@w3media/framework-styles/assets/bootstrap-4.js';
import '@w3media/framework-styles/assets/owl-carousel-2.js';
import '@w3media/framework-styles/assets/fancybox-3.js';
// import './scss/frontend.scss';

require('./scss/_vars.scss');
require('./scss/reservation.scss');

import '@w3media/framework-styles/assets/page-scripts.js';
import '@w3media/reservation/src/main.js';
