$(function() {
  if ($('#js-timeslot-datepicker').length <= 0) {
    return;
  }

  $('#js-timeslot-datepicker').on('changeDate', function() {
    date =  $(this).datepicker('getDate');

    $('.js-timeslot-item').each(function() {
      var ajaxUrl = $(this).data('ajax-url');
      ajaxUrl += "?date=" + [date.getFullYear(), date.getMonth()+1, date.getDate()].join('-');

      $.ajax({
        url: ajaxUrl,
        type: 'GET',
        data: {},
        dataType: 'json',
        success: function (response) {
          loadNewDateRangeRequest = false;
          if (response.success === true) {
            $('#timeslot-item-'+response.item_id).parent().html(response.html);
          }
        }
      });
    })
  });

  loadNewDateRangeRequest = false;
  $('body').on('click', '.js-timeslot-btn', function() {
    var ajaxUrl = $(this).data('ajax-url');

    if (loadNewDateRangeRequest !== false) {
      loadNewDateRangeRequest.abort();
    }

    loadNewDateRangeRequest = $.ajax({
      url: ajaxUrl,
      type: 'GET',
      data: {},
      dataType: 'json',
      success: function (response) {
        loadNewDateRangeRequest = false;
        if (response.success === true) {
          $('#timeslot-item-'+response.item_id).parent().html(response.html);
        }
      }
    });
  });
});
